import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Reveal from 'react-reveal';
import TeamGrid from '../../components/TeamGrid/TeamGrid';
import urls from '../../data/urls';

const Team = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "network"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <section>
      <TeamGrid
        title="The Founders"
        subtitle="Your main points of contact"
        cta="Let's get in touch"
        ctaUrl={urls.contactUs}
      >
        <TeamGrid.Item
          image="josh-wentworth.jpg"
          name="Josh Wentworth"
          description="Co-founder"
          linkedinLink="https://www.linkedin.com/in/joshwentworth/"
        />
        <TeamGrid.Item
          image="thomas-johnston.jpg"
          name="Thomas Johnston"
          description="Co-founder"
          linkedinLink="https://www.linkedin.com/in/trjohnst/"
        />
      </TeamGrid>
      <div className="sec_pad container text-center">
        <h2 className="f_p f_size_30 f_600 t_color3 l_height45 mb-30">
          Our Professional Network
        </h2>
        <p className="f_400">
          The Opna team is an expansive network of proven individuals from various backrounds. All vetted to guarantee only the top talent will be working on your projects.
        </p>
        <Reveal bottom cascade>
          <Img fluid={image} className="w-75 mx-auto my-4"/>
        </Reveal>
      </div>
    </section>
  );
}
export default Team;
